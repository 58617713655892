import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function CategoryTitle({ CategoryText, Categoryhref, moreClass, props }) {
    return (
        <h1 {...props} className={`ps-6 sm:text-[30px] font-bold mb-1 ${moreClass}`}>{CategoryText}

            <Link to={Categoryhref || ""} className="font-[400] sm:text-[20px]">

                <span className="px-3">|</span>

                Sell All

                <FontAwesomeIcon icon={faArrowRight} className="ms-3"></FontAwesomeIcon>

            </Link>

        </h1>
    )
}
