import Button from "../Button/Button";
import Input from "../Input/Input";
import { useNavigate } from "react-router-dom";

export default function Hero() {

    const navigate = useNavigate();

    return (

        <div className="bg-[#b2f7fd] min-h-[400px] overflow-hidden">

            <div className="container min-h-[400px] md:grid grid-cols-12">

                <div className="col-span-6 flex justify-center items-center flex-col min-h-[400px]">

                    <div>

                        <h3 className="font-bold">DISCOVER NOW LATEST COLLECTION</h3>

                        <h1 className="mb-5 text-[20px] sm:text-[50px] font-bold italic font-[auto]">Spring Collection {new Date().getFullYear()}</h1>

                        <div>

                            <Input onKeyDown={(e) => setTimeout(() => { e.key === "Enter" && navigate('search') }, 200)} placeholder={'Search'} classes={'mt-2'} />

                            <Button onClick={() => navigate('search')} classes={' ms-3 !p-3 bg-[#4f46e5] text-white hover:bg-[#0d00e5] transition-all mt-2'}>Search Now</Button>

                        </div>


                    </div>

                </div>

                <div className="col-span-6 hidden md:grid place-content-center">

                    <img src={require('./hero.png')} alt="hero bg" />

                </div>

            </div>

        </div>
    )
}
