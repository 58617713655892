import { Fragment } from "react";
import { Link } from "react-router-dom";

// This component displays an image with a "Hot" badge and a link to a product overview page
export default function ProductPromoImage({ src, alt }) {

    return (

        <Fragment>

            {/* "Hot" badge */}
            <div className="badge absolute left-0 top-20 uppercase font-bold text-white bg-red-500 px-2 py-1">Hot</div>

            {/* Product image with link to product overview page */}
            <div className="product-tumb flex items-center justify-center h-300 px-50 bg-gray-200">

                <Link to={"/productOverviews"}>

                    <img src={src} alt={alt} className="max-w-full max-h-full" />

                </Link>

            </div>

        </Fragment>

    )
}