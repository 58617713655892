import { Link } from "react-router-dom";

export default function PolicyLinks() {
    return (

        <div className="col-span-12 sm:col-span-6 lg:col-span-3">

            <ul>

                <h1 className="text-[17px] sm:text-[30px] font-semibold mb-3">Policy</h1>

                <Link to={"/policy"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Service and use policy</li>

                </Link>
                <Link to={"/policy"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Privacy policy</li>

                </Link>

                <Link to={"/policy"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Payment policy</li>

                </Link>

            </ul>

        </div>

    )
}
