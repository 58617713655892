import { Link } from "react-router-dom"
import ProductPromoImage from "./ProductPromoImage/ProductPromoImage"
import ProductDetails from "./ProductDetails/ProductDetails"

// This component displays a product card with an image, product details, and a link to a product details page
export default function ProductCard({ product }) {

    return (

        <div key={product.id} className="product-card relative bg-white shadow-md w-380 mx-auto my-50 rounded-[5px] overflow-hidden">

            {/* Display the product image and "Hot" badge */}
            <ProductPromoImage src={product.imageSrc} alt={product.imageAlt} />

            {/* Display the product details */}
            <div className="product-details p-3">

                {/* Display the product category */}
                <span className="product-catagory block uppercase font-bold text-gray-600 text-xs mb-4">{product.name}</span>

                {/* Display the product name with a link to the product details page */}
                <Link to={"/#"}>

                    <h4 className="text-uppercase font-medium text-gray-900 text-lg mb-4">

                        {product.name}

                    </h4>

                </Link>

                {/* Display the product description */}
                <p className="text-gray-600 text-base leading-6 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>

                {/* Display the product price and icons for adding the product to user's wishlist and cart */}
                <ProductDetails product={product} />

            </div>

        </div>

    )
}