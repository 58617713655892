import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LoginChoiceComponent() {
    return (
        <div className='my-10'>
            <div className="up-or-hr mb-3 h-[2px] relative text-center"><span className="span-hr absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[13px] font-bold opacity-70">Or</span></div>
            <div style={{ border: "1px solid #c8e2c8", borderRadius: "50px" }} className="text-center mb-3 relative bg-[#4285f4] text-white">
                <button className="google-btn transition-all p-2 w-full fw-bold text-black-50" style={{ background: "transparent", borderRadius: "inherit" }}>
                    <FontAwesomeIcon icon={faGoogle} className="google-icone absolute w-[20px] h-[20px] grid place-content-center rounded-full left-[10px] top-[50%] translate-y-[-50%]" />
                    Continue With Google
                </button>
            </div>

            <div style={{ border: "1px solid #c8e2c8", borderRadius: "50px" }} className="text-center mb-3 relative">
                <button className="p-2 w-full fw-bold hover:bg-[#c8e2c8] rounded-[20px]">
                    <FontAwesomeIcon icon={faApple} className="google-icone absolute w-[20px] h-[20px] grid place-content-center rounded-full left-[10px] top-[50%] translate-y-[-50%]" />
                    Continue With Apple
                </button>
            </div>
        </div>
    )
}
