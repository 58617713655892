import ProductCount from "./ProductCount/ProductCount";
import ProductStockStatus from "./ProductStockStatus/ProductStockStatus";
import ProductVariants from "./ProductVariants/ProductVariants";
import ProductActions from "./ProductActions/ProductActions";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const cartData = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const size = ["XS", "S", "M", "L", "XL", "2XL", "3XL"];
const color = ["Blue", "Pink", "Black", "Red", "Yellow"];

export default function CartProduct() {

    const [totalPrice, setTotalPrice] = useState(0);

    // set total price 

    useEffect(() => {

        const prices = [12.99, 12.99, 12.99, 12.99, 12.99, 12.99, 12.99, 12.99, 12.99];

        const total = prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setTotalPrice(total);

    }, []);


    const renderChartProduects = cartData.map(product => {

        return (

            <div key={product} className="py-5 pb-8 flex items-center flex-col md:flex-row border-b">


                <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg" width={100} className="md:me-5 rounded-[10px] mb-8 md:mb-0" alt="" />

                <div className="w-[95%]">

                    <ProductStockStatus price={12.99} />

                    <div className="flex justify-between items-center  flex-col md:flex-row">

                        <div className="flex items-center mb-3 md:mb-0 overflow-x-auto">

                            <ProductVariants options={size} selectType="Size" />

                            <ProductVariants options={color} selectType="Color" />

                            <ProductCount totalPrice={totalPrice} setTotalPrice={setTotalPrice} price={12.99} />

                        </div>

                        <ProductActions />

                    </div>


                </div>

            </div>

        )
    })

    return (

        <section className="select-none">

            <div className="container">

                <h1 className="px-2 md:px-0  font-semibold py-5 border-b flex items-center justify-between">

                    <span className="text-[15px] md:text-[30px]">Total Count: {(totalPrice).toFixed(2)}</span>

                    <Link to={'payment'}><Button bgColor={"#e94635"} classes={'rounded-[5px] !m-0 text-white'}>Check Out</Button></Link>

                </h1>

                <div className="my-10">

                    {renderChartProduects}


                </div>

            </div>

        </section>
    )
}
