import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    ShoppingCartIcon
} from '@heroicons/react/24/outline'

import { PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

import { Link } from 'react-router-dom'

import RelatedLinks from '../RelatedLinks/RelatedLinks'
import DesktopHeader from './DesktopHeader/DesktopHeader'
import MobileMenuActions from './MobileMenuActions/MobileMenuActions'
import MobileProductDropdown from './MobileProductDropdown/MobileProductDropdown'


const products = [
    { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
    { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
    { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
    { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
    { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
];

const mobileLinkes = [
    { text: "Features", href: "", classes: "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" },
    { text: "Marketplace", href: "/categories", classes: "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" },
    { text: "Company", href: "", classes: "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" },
];
const desktopLinks = [
    { text: "Features", href: "", classes: "text-sm font-semibold leading-6 text-gray-900" },
    { text: "Marketplace", href: "/categories", classes: "text-sm font-semibold leading-6 text-gray-900" },
    { text: "Company", href: "/company", classes: "text-sm font-semibold leading-6 text-gray-900" },
]

const callsToAction = [
    { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (

        <header className="bg-white drop-shadow relative z-50">

            <DesktopHeader products={products} setMobileMenuOpen={setMobileMenuOpen} callsToAction={callsToAction} desktopLinks={desktopLinks} />

            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>

                <div className="fixed inset-0 z-10" />


                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-[250px] transition sm:w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">


                    <MobileMenuActions setMobileMenuOpen={setMobileMenuOpen} />

                    <div className="mt-6 flow-root">

                        <div className="-my-6 divide-y divide-gray-500/10">

                            <div className="space-y-2 py-6">

                                <MobileProductDropdown products={products} classNames={classNames} callsToAction={callsToAction} />

                                <RelatedLinks list={mobileLinkes} />

                            </div>

                            <div className="py-6">

                                <Link to="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">

                                    Log in / Register

                                </Link>

                                <Link to="/cart" className="-mx-3 flex items-center justify-center rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">

                                    <ShoppingCartIcon className="h-5 w-5 flex-none text-gray-400 me-4" aria-hidden="true" />

                                    Cart

                                </Link>

                            </div>

                        </div>

                    </div>

                </Dialog.Panel>


            </Dialog>

        </header>
    )
}
