import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    initialState: [],
    name: "cartSlice",
    reducers: {
        addToCart: (state, action) => {
            // const find = state.find(product => +product.id == +action.payload.id);
            // if (!find) {
            //     state.push(action.payload);
            // }
            console.log(action.pa)
        },
        removeFromCart: (state, action) => {
            return action.payload ? state.filter(card => Number(card.id) !== Number(action.payload)) : state;
        }
    }
});


export default cartSlice.reducer;

export const { addToCart, removeFromCart } = cartSlice.actions;