import { Link } from "react-router-dom";

export default function HowItWorksLink() {
    return (

        <div className="col-span-12 sm:col-span-6 lg:col-span-3">

            <ul>

                <h1 className="text-[17px] sm:text-[30px] font-semibold mb-3">About</h1>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">About Brand</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">How does this brand guarantee your rights?</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">How does this brand Work</li>

                </Link>

            </ul>

        </div>

    )
}
