import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";

// an array of data used to generate the slides in the carousel
const cardsData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

// This component is a responsive image carousel that displays a collection of brand products in a sliding format, with navigation buttons and pagination indicators for ease of use.
export default function BrandProducts() {

    // map over the cardsData array and create a new array of JSX elements for each slide
    const configreCards = cardsData.map(card => {

        return (

            <SwiperSlide key={card}>

                <Link to={""}>

                    <div className="p-3 bg-gray-100 flex flex-col items-center rounded-[5px]">

                        <img src={require('./adve-2.webp')} alt="" className="w-full h-[150px] mb-3" />

                        <p>Shoose</p>

                    </div>

                </Link>

            </SwiperSlide>

        )

    });

    // return the SwiperWrapper component with the slides, slides per view count, and autoplay delay props
    return (

        <SwiperWrapper items={configreCards} slidesPerViewCount={[1.5, 4.5, 5.5, 6.5]} autoplayDelay={2000}></SwiperWrapper>

    )
}