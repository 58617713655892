import { Link } from 'react-router-dom'

export default function HeaderLogo() {
    return (

        <div className="flex lg:flex-1">

            <Link to="" className="-m-1.5 p-1.5">

                <span className="sr-only">Your Company</span>

                <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />

            </Link>

        </div>

    )
}
