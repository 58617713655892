
import ProductReviews from './ProductReviews/ProductReviews'
import ProductColorOptions from './ProductColorOptions/ProductColorOptions'
import ProductSizeOptions from './ProductSizeOptions/ProductSizeOptions'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductActionsOverview({ product , setSeeReviews }) {



    return (
        <div className="mt-4 col-span-12">

            <h2 className="sr-only">Product information</h2>


            {/* Reviews */}

            <ProductReviews setSeeReviews={setSeeReviews} classNames={classNames} />

            <form className="mt-10" onSubmit={e => e.preventDefault()}>

                {/* Colors */}

                <ProductColorOptions product={product} classNames={classNames} />

                {/* Sizes */}

                <ProductSizeOptions product={product} classNames={classNames} />


                <button
                    className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >

                    Add to bag

                </button>

            </form>

        </div>
    )
}
