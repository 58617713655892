import { Link } from "react-router-dom"

export default function RelatedLinks({ list }) {
    return (

        list.map((link, index) => {
            return (
                <Link
                    key={index + 1}
                    to={link.href} className={link.classes}>
                    {link.text}
                </Link>
            );
        })

    )
}
