import { useEffect } from "react";
import Button from "../Button/Button";

// This function handles the visibility of the button based on the user's scroll position
const handleScrollPositionForUpButton = () => {
    const button = document.querySelector('.up-button');

    if (window.scrollY > 500) {

        button.style.cssText += "transform: scale(1); opacity: 1;";

    } else {

        button.style.cssText += "transform: scale(0); opacity: 0;";
        
    }
}

// This component provides a button that, when clicked, scrolls the user's view to the top of the page
export default function BackToTopButton() {

    // This effect hook is used to add a scroll event listener and handle the visibility of the button based on the user's scroll position
    useEffect(() => {

        // This function handles the visibility of the button based on the user's scroll position
        handleScrollPositionForUpButton();

        // This adds a scroll event listener to the window object and calls the handleScrollPositionForUpButton function when the user scrolls
        window.addEventListener('scroll', () => handleScrollPositionForUpButton());

        // This is a cleanup function that removes the scroll event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScrollPositionForUpButton());
        }
    }, []);

    // This returns the Button component with the text "Up" and an onClick event listener that scrolls the user's view to the top of the page when clicked
    return (

        <Button
            onClick={_ => window.scrollTo({ top: 0, behavior: "smooth" })}
            classes={'up-button ms-3 !p-6 !py-1 bg-[#4f46e5] font-bold fixed right-3 bottom-3 text-white hover:bg-[#0d00e5] transition-all mt-2 z-[555] drop-shadow'}>
            Up
        </Button>
    );
}