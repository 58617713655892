import Hero from "../Hero/Hero";
import LayOut from "../layOuts/LayOut";
import { Fragment } from "react";



const Home = () => {

    return (

        <Fragment>

            <Hero />

            <LayOut />

        </Fragment>
    )
}

export default Home;