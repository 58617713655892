const checkboxData = [
    {
        id: "up-checkbox-email",
        for: "up-checkbox-email",
        text: "Send me emails with tips on how to find talent that fits my needs."
    },
    {
        id: "up-checkbox-policy",
        for: "up-checkbox-policy",
        text: "Yes, I understand and agree to the Watch 4u Terms of Service , including the User Agreement and Privacy Policy ."
    }
]
const UserAgreementCheckboxes = () => {
    const renderCheckboxs = checkboxData.map((box, i) => {
        return (
            <p key={i} className="flex items-center my-3">
                <input type="checkbox" id={box.id} className="w-auto cursor-pointer me-2 mb-0" />
                <label style={{ fontSize: "13px", cursor: "pointer" }} className="select-none" htmlFor={box.for}>{box.text}</label>
            </p>
        )
    })
    return (
        
        <div className="mt-8">

            {renderCheckboxs}

        </div>
    );
}

export default UserAgreementCheckboxes;