import { Fragment, useEffect, useState } from "react";
import Input from "../../Input/Input";
import UserAgreementCheckboxes from "../UserAgreementCheckboxes/UserAgreementCheckboxes";
import { validateInput } from "../../utilities/utilities";

const inputStyle = "text-black !p-1 !ps-3 w-full";
const inputData = [
    {
        type: "text",
        placeholder: "email address",
        inputName: 'Email'
    },
    {
        type: "password",
        placeholder: "Password (more than 8 characters, including 2 characters)",
        inputName: 'Password'
    },
    {
        type: "password",
        placeholder: "Confirm Password",
        inputName: 'Confirm Password'
    },
]

const AccountCreationForm = () => {

    const [password, setPassword] = useState();

    const [inputErrorMessage, setInputErrorMessage] = useState({
        'First Name': '',
        'Last Name': '',
        Email: '',
        Password: '',
        'Confirm Password': '',

    });

    useEffect(() => {

        const errors = document.querySelectorAll('.on-error');

        if (errors) {

            Array.from(errors).map(error => error.style.cssText += "scale: 1 !important; opacity: 1 !important;");

        }

    }, [inputErrorMessage]);

    return (

        <Fragment>

            <div className="flex justify-between items-center relative">

                <div className="w-[48.5%] me-2 relative py-2">
                    <Input type="text" classes={`${inputStyle} !min-w-[100%]`} style={{ border: "2px solid #e4ebe4" }} onChange={e => validateInput(e.target, 'First Name', setInputErrorMessage)} placeholder="First name without space" />
                    <label className="on-error">{inputErrorMessage['First Name']}</label>
                </div>
                <div className="w-[48.5%] me-2 relative py-2">
                    <Input type="text" classes={`${inputStyle} !min-w-[48.5%] w-[48.5%]`} style={{ border: "2px solid #e4ebe4" }} onChange={e => validateInput(e.target, 'Last Name', setInputErrorMessage)} placeholder="Last name without space" />
                    <label className="on-error">{inputErrorMessage['Last Name']}</label>
                </div>

            </div>

            {/* // This maps over the array of input data and returns an Input component for each item, passing in the input's type,
             placeholder, and a key for React to keep track of each component */}

            {
                inputData.map((input, i) => {
                    return (
                        <div className="relative py-3">
                            <Input key={i} type={input.type} onChange={e => validateInput(e.target, input.inputName, setInputErrorMessage, password, setPassword)} classes={inputStyle} style={{ border: "2px solid #e4ebe4" }} placeholder={input.placeholder} />
                            <label className="on-error">{inputErrorMessage[input.inputName]}</label>
                        </div>
                    )
                })
            }

            <UserAgreementCheckboxes />

        </Fragment>

    );
}

export default AccountCreationForm;