import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button'
import LoginChoiceComponent from '../LoginChoiceComponent/LoginChoiceComponent'
import Input from '../Input/Input'
import { Link } from 'react-router-dom'

export default function Login() {
    return (

        <div className='rounded-[10px] border border-[#72ba69] p-5 w-[95%] sm:w-[600px] m-auto my-14'>

            <h1 className='p-3 font-medium text-[26px] text-center mt-5'>Log In Our Brand</h1>

            <div className='!m-auto my-3 relative'>

                <FontAwesomeIcon icon={faUser} className='absolute top-[30px] left-3 text-black z-10'></FontAwesomeIcon>

                <Input inputType="text" classes="w-full text-black !p-1 mt-0 !px-8 !mb-1" style={{ border: "2px solid #e4ebe4" }} placeholder="Username or Email" />

            </div>

            <div className='!m-auto  relative'>

                <FontAwesomeIcon icon={faLock} className='absolute top-[30px] left-3 text-black z-10'></FontAwesomeIcon>

                <Input inputType="password" classes="w-full text-black mt-0 !p-1 !px-8 !mb-1" style={{ border: "2px solid #e4ebe4" }} placeholder="Password" />

            </div>

            <Button bgColor="#108a00" classes="w-full m-auto mt-10 block rounded-[20px] text-white">Login</Button>

            <LoginChoiceComponent />

            <div className=' relative my-14'>

                <p className="font-bold text-[13px] text-center">You Don't Have Account?</p>

                <Link to={"/regeister"}>

                    <Button classes="border-2 m-auto my-3 block border-[#72ba69] w-[75%]  rounded-[50px] hover:bg-[#108a004d] font-bold">Sign Up</Button>

                </Link>

            </div>

        </div>
    )
}
