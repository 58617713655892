import React, { useEffect, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { faCameraRetro, faHome, faChildReaching, faChildren, faComputer, faGamepad, faLaptop, faMobileScreen, faPersonDress, faPlugCircleExclamation, faShirt, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';


const items = [

    {
        label: 'Clothes',
        icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faShirt} />,
        expanded: true,
        items: [
            {
                label: 'Men',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faShirt} />,
                url: '/categories?clothes&category=men',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Women',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faPersonDress} />,
                url: '/categories?clothes&category=women',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Boys',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faChildReaching} />,
                url: '/categories?clothes&category=boys',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Girls',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faPersonDress} />,
                url: '/categories?clothes&category=girls',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Children',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faChildren} />,
                url: '/categories?clothes&category=children',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            }
        ]
    },

    {
        label: 'Electronics',
        icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faComputer} />,
        expanded: true,
        items: [
            {
                label: 'Laptop',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faLaptop} />,
                url: '/categories?electronics&category=laptop',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Smart Phones',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faMobileScreen} />,
                url: '/categories?electronics&category=smartphones',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Cameras',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faCameraRetro} />,
                url: '/categories?electronics&category=cameras',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Gaming',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faGamepad} />,
                url: '/categories?electronics&category=gaming',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            }
        ]
    },

    {
        label: 'Remote Control',
        icon: 'pi pi-fw pi-user',
        expanded: true,
        items: [
            {
                label: 'TV Remotes',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faTv} />,
                url: '/categories?remotes&category=tvRemotes',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'Receiver Remotes',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faGamepad} />,
                url: '/categories?remotes&category=receiverRemotes',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            },
            {
                label: 'LED/LCD Remotes',
                icon: <FontAwesomeIcon className='me-2 w-[20px]' icon={faPlugCircleExclamation} />,
                url: '/categories?remotes&category=ledRemotes',
                template: ({ label, icon, url }) => (
                    <Link to={url} className="p-panelmenu-header-link py-3 px-5">
                        <span className="p-panelmenu-header-icon">{icon}</span>
                        <span className="p-panelmenu-header-text">{label}</span>
                    </Link>
                )
            }
        ]
    }
];


export default function PanelCategories() {
    const [searchParams, setSearchParams] = useState('')
    const breadcrumbItems = [{ label: 'Categories' , url: "categories" }, { label:  searchParams }];
    const home = { icon: <FontAwesomeIcon icon={faHome} />, url: '/' };

    useEffect(() => {

        const searchLocation =  window.location.search.slice((window.location.search.indexOf("=") + 1));
        setSearchParams(searchLocation);
        
    }, [searchParams]);
    return (

        <div>
            <BreadCrumb model={breadcrumbItems} home={home} className='mb-5 !text-[13px]' />
            <PanelMenu model={items} onClick={e => setSearchParams('')} multiple />

        </div>
    );
}