import { Link } from "react-router-dom";
import ProductDetails from "../../Cards/ProductDetails/ProductDetails";
import ProductPromoImage from "../../Cards/ProductPromoImage/ProductPromoImage";

export default function CategoryCard({ product }) {
    return (
        <div className="product-card relative bg-white shadow-md  mx-auto my-50 rounded-[5px] overflow-hidden">

            <ProductPromoImage src={product.imageSrc}  alt={product.imageAlt} />

            <div className="product-details p-3">

                <span className="product-catagory block uppercase font-bold text-gray-600 text-xs mb-4">{product.name.slice(0 , 15)}...</span>

                <Link to={"/"}>

                    <h4 className="text-uppercase font-medium text-gray-900 text-lg mb-4">

                        {product.name.slice(0 , 15)}..

                    </h4>

                </Link>

                <p className="text-gray-600 text-base leading-6 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>

                <ProductDetails product={product} />

            </div>

        </div>
    )
}
