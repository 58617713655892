import { faFacebook, faGithub, faInstagram, faLinkedin, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

export default function SocialMediaLinks() {
    return (

        <div className="col-span-12 sm:col-span-6 flex items-center justify-center">

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>

            </Link>

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>

            </Link>

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faPinterest}></FontAwesomeIcon>

            </Link>

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>

            </Link>

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>

            </Link>

            <Link to={""} className="w-[40px] h-[40px] rounded-[3px] flex items-center justify-center bg-gray-300 me-3">

                <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>

            </Link>

        </div>

    )
}
