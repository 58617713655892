import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Rating } from "primereact/rating";

const categories = [
    { name: "All Categories", count: parseInt(Math.random() * 1000) },
    { name: "Clothes", count: parseInt(Math.random() * 1000) },
    { name: "Men", count: parseInt(Math.random() * 1000) },
    { name: "Women", count: parseInt(Math.random() * 1000) },
    { name: "Boys", count: parseInt(Math.random() * 1000) },
    { name: "Smart Phone", count: parseInt(Math.random() * 1000) },
    { name: "Cameras", count: parseInt(Math.random() * 1000) },
    { name: "Gaming", count: parseInt(Math.random() * 1000) },
    { name: "Remote Controal", count: parseInt(Math.random() * 1000) },
];

const categoriesRate = [
    { rate: 4, id: "cat-full-rate" },
    { rate: 3, id: 'cat-rate-3' },
    { rate: 2, id: 'cat-rate-2' },
    { rate: 1, id: 'cat-rate-1' },
]
const categoriesLevel = [
    { level: "High", id: "high-level" },
    { level: "Meduim", id: 'medium-level' },
    { level: "Weak", id: 'weak-level' },
]

export default function FilterPanel() {
    const [checked, setChecked] = useState(false);
    const [ingredients, setIngredients] = useState([]);

    const breadcrumbItems = [{ label: 'Categories', url: "categories" }, { label: 'search' }];

    const home = { icon: <FontAwesomeIcon icon={faHome} />, url: '/' };

    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }

    return (
        <section >
            <BreadCrumb model={breadcrumbItems} home={home} className='mb-5 !text-[13px]' />


            <div>

                <div className='mt-12'>
                    <p className='font-medium mb-3'>Search</p>
                    <span className="p-input-icon-left w-full">
                        <i className="pi pi-search z-10" />
                        <InputText placeholder="Search" className='w-full' />
                    </span>

                </div>

                <div className='my-12'>

                    <p className='font-medium mb-3'>Categories</p>

                    {
                        categories.map((cat, index) => {
                            return <Link to={''} key={index} className='flex items-center justify-between mb-2 transition-all hover:font-bold hover:text-[#e94635]'>

                                <p className='text-[15px]'>{cat.name}</p>
                                <p className='bg-black text-white rounded-[5px] min-w-[30px] min-h-[30px] flex items-center justify-center text-[13px]'>
                                    {cat.count}
                                </p>


                            </Link>
                        })
                    }

                </div>

                <div className='my-12'>

                    <p className='font-medium mb-3'>Categories Rate</p>

                    {categoriesRate.map((cat, index) => {
                        return <div key={index} className="flex items-center cursor-pointer mb-2">

                            <Checkbox inputId={cat.id} value={cat.id} onChange={onIngredientsChange} checked={ingredients.includes(cat.id)} className='rounded-full me-2 cursor-pointer'></Checkbox>

                            <label className="card me-2 flex items-center cursor-pointer" htmlFor={cat.id}>

                                <Rating value={cat.rate} disabled cancel={false} />

                                <p className='ms-2 select-none text-[13px] font-normal'>or More</p>

                            </label>


                        </div>
                    })}




                </div>

                <div className='my-12'>

                    <p className='font-medium mb-3'>Categories Level</p>

                    {categoriesLevel.map((cat, index) => {
                        return <div key={index} className="flex items-center cursor-pointer mb-2">

                            <Checkbox inputId={cat.id} value={cat.id} onChange={onIngredientsChange} checked={ingredients.includes(cat.id)} className='rounded-full me-2 cursor-pointer'></Checkbox>

                            <label className="card me-2 flex items-center cursor-pointer" htmlFor={cat.id}>


                                <p className='ms-2 select-none text-[13px] font-normal'>{cat.level}</p>

                            </label>


                        </div>
                    })}

                </div>

            </div>

        </section>
    )
}
