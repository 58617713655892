import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function MobileProductDropdown({products , callsToAction, classNames }) {
    return (
        <Disclosure as="div" className="-mx-3">

            {({ open }) => (

                <>

                    <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">

                        Product

                        <ChevronDownIcon className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')} aria-hidden="true" />

                    </Disclosure.Button>

                    <Disclosure.Panel className="mt-2 space-y-2">

                        {[...products, ...callsToAction].map((item) => (

                            <Disclosure.Button key={item.name} as="a" href={item.href} className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" >

                                {item.name}

                            </Disclosure.Button>

                        ))}

                    </Disclosure.Panel>

                </>

            )}

        </Disclosure>
    )
}
