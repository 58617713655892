import { Link } from "react-router-dom";

export default function MarketplaceLink() {
    return (

        <div className="col-span-12 sm:col-span-6 lg:col-span-3">

            <ul>

                <h1 className="text-[17px] sm:text-[30px] font-semibold mb-3">Links</h1>

                <Link to={"/login"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Login / Register</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Company</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Products</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Features</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Marketplace</li>

                </Link>

            </ul>

        </div>

    )
}
