import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollar } from '@fortawesome/free-solid-svg-icons'


const cardsData = [
    {
        icon: faDollar,
        name: "International delivery",
        descripe: "Get your order in 2 years"
    },
    {
        icon: faDollar,
        name: "Loyalty rewards",
        descripe: "Don't look at other tees"
    }
]

export default function ProductBoxsOverview() {

    const renderCards = cardsData.map((card, index) => {
        return (
            <div key={index} className='col-span-6 place-content-center bg-[#f9fafb] p-3 rounded-[6px] text-center border border-gray-300'>

                <div className='mb-2 m-auto flex justify-center items-center w-[30px] h-[30px] rounded-full border border-gray-300'>

                    <FontAwesomeIcon icon={card.icon}></FontAwesomeIcon>

                </div>

                <h4 className='font-bold text-md'>{card.name}</h4>

                <p className='text-sm'>{card.descripe}</p>

            </div>
        )
    })
    return (

        <div className="mt-10 grid grid-cols-12 gap-3">

            {renderCards}

        </div>
    )
}
