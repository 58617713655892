import Button from "../Button/Button";
import AccountCreationForm from "./AccountCreationForm/AccountCreationForm";
import Sociallogin from "../LoginChoiceComponent/LoginChoiceComponent";


export default function Regeister() {
  return (

    <div className="bg-white">

      <div className="sign-up-wrapper w-full my-[70px] p-3 flex flex-col justify-center items-center">

        <div className="container flex justify-center items-center">

          <div className="p-3 mw-100 border border-[#ccc] rounded-xl w-[600px]" >

            <form onSubmit={e => e.preventDefault()}>

              <h2 className="text-center mb-5 mt-3 font-medium" style={{ fontStyle: "oblique" }}>

                Sign up to Enjoy Watching

              </h2>

              {/* this is the form sign up inputs componant */}
              <AccountCreationForm />

              <Button classes="w-full !p-3 mt-5 !my-10 rounded-[10px] main-btn">Sign Up</Button>
            </form>

            {/* this is the Sign Up with google or apple componant  */}
            <Sociallogin />

          </div>

        </div>

      </div>

    </div>

  );

}
