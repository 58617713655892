import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";

function decrementProductCount(productCount, setProductCount, totalPrice, setTotalPrice, price) {

    if (productCount > 1) {

        setProductCount(+productCount - 1);

        setTotalPrice(+totalPrice - price);

    }

}
function incrementProductCount(productCount, setProductCount, totalPrice, setTotalPrice, price) {

    if (productCount < 10) {

        setProductCount(productCount + 1);

        setTotalPrice(+totalPrice + price);

    }

};
export default function ProductCount({ price, totalPrice, setTotalPrice }) {

    let [productCount, setProductCount] = useState(1);

    return (

        <Fragment>

            <div className="px-2 rounded-[10px] w-auto text-[11px] sm:text-[16px] flex justify-between items-center">

                <div className="bg-[#eee] w-[25px] h-[25px] flex items-center justify-center">

                    <FontAwesomeIcon icon={faMinus} className="text-[11px] font-bold text-gray-700 cursor-pointer" onClick={() => decrementProductCount(productCount, setProductCount, totalPrice, setTotalPrice, price)}></FontAwesomeIcon>

                </div>
                <p className="font-bold mx-3">

                    ${parseInt(price * productCount)}.00

                </p>
                <div className="bg-[#eee] w-[25px] h-[25px] flex items-center justify-center">

                    <FontAwesomeIcon icon={faPlus} className="text-[11px] font-bold text-gray-700 cursor-pointer" onClick={() => incrementProductCount(productCount, setProductCount, totalPrice, setTotalPrice, price)}></FontAwesomeIcon>

                </div>

            </div>

        </Fragment>
    )
}
