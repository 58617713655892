
export default function ProductStockStatus({price}) {

    return (

        <div className="flex justify-between items-center mb-5">

            <div>

                <h3>Relaxed Fit T-shirt</h3>

                <p>

                    <span className="text-gray-200 font-medium">{price} | </span>

                    <span className=" text-[#00ff00] font-medium">In Stock</span>

                </p>

            </div>

            <h1 className="font-bold">${price}</h1>

        </div>

    )
}
