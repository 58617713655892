import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Input from "../Input/Input";
import Button from "../Button/Button";
import ProductAds from "../Advertisements/ProductAds";
import BrandProducts from "../Brands/BrandProducts";
import ProductCard from "../Cards/ProductCard";
import { products } from "../utilities/utilities";
import CategoryTitle from "../CategoryTitle/CategoryTitle";


export default function LayOut() {

    return (

        <div className="py-8">

            <div className="container">


                <CategoryTitle CategoryText={'Explore Popular Categories'} />

                <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6  lg:max-w-7xl lg:px-8">

                    <div className="grid grid-cols-12 gap-5">
                        {products.map((product) => {
                            return <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">

                                <ProductCard product={product} />
                            </div>
                        })}
                    </div>

                    <ProductAds />

                </div>

                <CategoryTitle CategoryText={'Explore Brands'} />

                <div className="mx-auto max-w-2xl px-4  sm:px-6 py-3 lg:max-w-7xl lg:px-8">

                    <BrandProducts />

                </div>

                <div className="mx-auto max-w-2xl px-4 mt-32 sm:px-6 py-8 lg:max-w-7xl lg:px-8 text-center bg-gray-100">

                    <h1 className="ps-6 sm:text-[30px] font-bold mb-1">

                        Subscribe to our Newsletter

                    </h1>

                    <p className="font-bold text-sm text-gray-500">Subscribe to our update and be the first to know about new services and projects</p>

                    <div>

                        <Input placeholder={'Enter Your Email'} classes={'mt-2'} />

                        <Button classes={' ms-3 !p-3 bg-[#4f46e5] text-white hover:bg-[#0d00e5] transition-all mt-2'}>Subscribe</Button>

                    </div>

                </div>

            </div>

        </div>

    )
}
