import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function MobileMenuActions({ setMobileMenuOpen }) {
    return (

        <div className="flex items-center justify-between">

            <Link to="" className="-m-1.5 p-1.5">

                <span className="sr-only">Your Company</span>

                <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />

            </Link>

            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)} >

                <span className="sr-only">Close menu</span>

                <XMarkIcon className="h-6 w-6" aria-hidden="true" />

            </button>

        </div>

    )
}
