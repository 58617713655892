
import Breadcrumb from './Breadcrumb/Breadcrumb'
import ProductGalleryOverview from './Breadcrumb/ProductGalleryOverview/ProductGalleryOverview'
import ProductActionsOverview from './ProductActionsOverview/ProductActionsOverview'
import ProductFabric from './ProductFabric/ProductFabric'
import ProductBoxsOverview from './ProductBoxsOverview/ProductBoxsOverview'
import ReviewsAndRatings from '../ReviewsAndRatings/ReviewsAndRatings'
import { useEffect, useState } from 'react'
import SwiperWrapper from '../SwiperWrapper/SwiperWrapper'
import { products } from '../utilities/utilities'
import { SwiperSlide } from 'swiper/react'
import ProductCard from '../Cards/ProductCard'
import CategoryTitle from '../CategoryTitle/CategoryTitle'

const product = {
    name: 'Basic Tee 6-Pack',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Men', href: '#' },
        { id: 2, name: 'Clothing', href: '#' },
    ],
    images: [
        {
            src: 'https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg',
            alt: 'Two each of gray, white, and black shirts laying flat.',
        },
        {
            src: 'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-01.jpg',
            alt: 'Model wearing plain black basic tee.',
        },
        {
            src: 'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-02.jpg',
            alt: 'Model wearing plain gray basic tee.',
        }
    ],
    colors: [
        { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
        { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
        { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
    ],
    sizes: [
        { name: 'XXS', inStock: false },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: '2XL', inStock: true },
        { name: '3XL', inStock: true },
    ],
    description:
        'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
    highlights: [
        'Hand cut and sewn locally',
        'Dyed with our proprietary colors',
        'Pre-washed & pre-shrunk',
        'Ultra-soft 100% cotton',
    ],
    details:
        'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
}

const renderProducts = products.map((product) => {
    return <SwiperSlide key={product.id}><ProductCard product={product}></ProductCard></SwiperSlide>
});

export default function ProductOverviews() {

    const [seeReviews, setSeeReviews] = useState(false);

    useEffect(() => {
        seeReviews ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
    }, [seeReviews])

    return (

        <div className="bg-white">

            {seeReviews && <ReviewsAndRatings setSeeReviews={setSeeReviews} />}

            <div className="pt-6 grid grid-cols-12 gap-3">

                <Breadcrumb product={product} />
                <div className='container col-span-12 grid grid-cols-12 gap-3'>

                    {/* Image gallery */}

                    <ProductGalleryOverview product={product} />

                    {/* Product info */}

                    <div className="mx-auto grid grid-cols-12 gap-3 col-span-12 md:col-span-6 max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">

                        <div className="col-span-12 flex justify-between items-center">

                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>

                            <p className="text-lg font-bold tracking-tight text-gray-900 ">{product.price}</p>

                        </div>

                        {/* Options */}

                        <ProductActionsOverview setSeeReviews={setSeeReviews} product={product} />

                        <div className="py-10 col-span-12">

                            {/* Description and details */}

                            <div>

                                <h3 className="sr-only">Description</h3>


                                <div className="space-y-6">

                                    <p className="text-base text-gray-900 text-justify">{product.description}</p>

                                </div>

                            </div>

                            <ProductFabric product={product} />

                            <ProductBoxsOverview />

                        </div>

                    </div>

                </div>

            </div>

            <div className='container '>

                <CategoryTitle moreClass="!mt-16" CategoryText={'Related Products'} />

                <div className='my-12'>

                    <SwiperWrapper items={renderProducts} slidesPerViewCount={[2, 3, 3.5, 3.5]} autoplayDelay={3000} />
                </div>

            </div>

        </div>
    )
}
