
import PanelCategories from './PanelCategories/PanelCategories';

export default function Panel() {

    return (
        <aside className="grid col-span-12 md:col-span-4 lg:col-span-3">

            <PanelCategories />

        </aside>
    )
}