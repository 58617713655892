import { Bars3Icon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import CallToActionList from "../CallToActionList/CallToActionList";
import { Fragment } from "react";
import RelatedLinks from "../../RelatedLinks/RelatedLinks";
import { Link } from "react-router-dom";
import ProductsLinks from "../ProductsLinks/ProductsLinks";



export default function DesktopHeader({ products, setMobileMenuOpen, callsToAction, desktopLinks }) {
    return (

        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">

            <HeaderLogo />

            <div className="flex lg:hidden">

                <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>

                    <span className="sr-only">Open main menu</span>

                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />

                </button>

            </div>

            <Popover.Group className="hidden lg:flex lg:gap-x-12">

                <Popover className="relative">

                    <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">

                        Product

                        <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

                    </Popover.Button>


                    <Transition as={Fragment} enter="transition ease-in duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">

                        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen  max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">

                            <ProductsLinks products={products} />

                            <CallToActionList callsToAction={callsToAction} />

                        </Popover.Panel>

                    </Transition>

                </Popover>


                <RelatedLinks list={desktopLinks} />

            </Popover.Group>

            <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">

                <Link to="/login" className="text-sm font-semibold leading-6 text-gray-900 me-6">

                    Log in / Register

                </Link>

                <Link to="/cart" className="text-sm font-semibold leading-6 text-gray-900">

                    <ShoppingCartIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

                </Link>

            </div>

        </nav>


    )
}
