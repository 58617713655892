import { Link } from "react-router-dom";

export default function SupportLinks() {
    return (

        <div className="col-span-12 sm:col-span-6 lg:col-span-3">

            <ul>

                <h1 className="text-[17px] sm:text-[30px] font-semibold mb-3">Support</h1>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">Contact us for assistance</li>

                </Link>

                <Link to={"/"} className="text-gray-500">

                    <li className="w-fit transition-all font-light mb-3">01125480514</li>

                </Link>

            </ul>

        </div>

    )
}
