// This component is a reusable button that accepts text, background color, and additional classes as props, and renders a styled button element with the specified properties.
export default function Button(props) {

    // destructure the props object to get the button text, background color, and additional classes
    const { btnText, bgColor, classes, ...rest } = props;

    // return a button element with the specified properties
    return (

        <button className={`py-2  px-5 rounded-[5px] ${classes}`} style={{ backgroundColor: bgColor }} {...rest}>

            {props.children}

        </button>

    )
}