import CategoryCard from './CategoryCard/CategoryCard';
import Panel from './Panel/Panel';
import Footer from '../Footer/Footer';
import React, { useState, useEffect, Fragment } from 'react';
import { DataView } from 'primereact/dataview';


class ProductService {
    static async getProducts() {

        const data = [
            {
                id: 1,
                name: 'Earthen Bottle',
                href: '#',
                price: '$48',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg',
                imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
            },
            {
                id: 2,
                name: 'Nomad Tumbler',
                href: '#',
                price: '$35',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg',
                imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
            },
            {
                id: 3,
                name: 'Focus Paper Refill',
                href: '#',
                price: '$89',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg',
                imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
            },
            {
                id: 4,
                name: 'Machined Mechanical Pencil',
                href: '#',
                price: '$35',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg',
                imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
            },
            // More products...
            {
                id: 5,
                name: 'Earthen Bottle',
                href: '#',
                price: '$48',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg',
                imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
            },
            {
                id: 6,
                name: 'Nomad Tumbler',
                href: '#',
                price: '$35',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg',
                imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
            },
            {
                id: 7,
                name: 'Focus Paper Refill',
                href: '#',
                price: '$89',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg',
                imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
            },
            {
                id: 8,
                name: 'Machined Mechanical Pencil',
                href: '#',
                price: '$35',
                imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg',
                imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
            },
        ]
        return data;
    }
}

export default function Categories() {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        ProductService.getProducts().then((data) => setProducts(data));
    }, []);

    const gridItem = (product) => {
        return (
            // Render a div with class 'col-span-12 sm:col-span-4 lg:col-span-3 xl:col-span-2 p-2'
            <div className="col-span-12 sm:col-span-4 lg:col-span-3 xl:col-span-4 p-2">

                {/* Render CategoryCard ProvidersCard component */}
                <CategoryCard product={product} />

            </div>
        );
    };
    const itemTemplate = (product) => {
        if (!product) return;
        return gridItem(product);
    };
    return (

        <Fragment>
            <section>

                <div className='container'>

                    <div className='grid grid-cols-12 gap-4 p-3'>

                        <Panel />
                        <div className='col-span-12 md:col-span-8 lg:col-span-9'>

                            <div className="card">

                                <DataView value={products} paginator rows={6} emptyMessage={'No Data Found'} itemTemplate={itemTemplate} layout={'grid'} />

                            </div>

                        </div>

                    </div>

                </div>

            </section>
            
        </Fragment>

    )
}
