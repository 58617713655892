import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from "react";
import { Rating } from "primereact/rating";

const sectionsRate = [
    { name: "Reliabilty", count: 4.1, width: 75, bgColor: "#f39b25" },
    { name: "Payout Rating", count: 4.3, width: 85, bgColor: "#13d1a3" },
    { name: "Positive Solutions", count: 4.1, width: 60, bgColor: "#13d1a3" },
];

const renderSectionRate = sectionsRate.map((section, index) => {
    return (
        <div key={index} className='mt-5'>
            <div className='flex items-center justify-between font-medium mb-3'>
                <h5>{section.name}</h5>
                <h5>{section.count}</h5>
            </div>
            <div className='bg-[#eaf0f3] w-full h-[5px] rounded-[10px]'>
                <div className='h-full rounded-[10px]' style={{ backgroundColor: section.bgColor, width: section.width + "%" }}></div>
            </div>
        </div>
    )
})

export default function ReviewsAndRatings({ setSeeReviews }) {

    const [value, setValue] = useState(null);



    return (
        <div onClick={() => setSeeReviews(false)} className="transition-all absolute bg-[#edf4ffe0] w-full flex items-center justify-center z-50" style={{ height: 'calc(100% - 80px)' }}>
            <div onClick={(e) => e.stopPropagation()} className='bg-white p-4 py-16 rounded-[6px] w-[500px] m-auto'>

                <div className='mb-3 w-[30px] h-[30px] rounded-full bg-white cursor-pointer flex items-center justify-center'>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={() => setSeeReviews(false)}></FontAwesomeIcon>
                </div>

                <h1 className='mb-3 font-bold text-[40px]'>Reviews And Rating</h1>

                <div className='flex items-center border-b pb-3'>
                    <h1 className='text-[40px] font-bold text-[#333a3c] me-4'>4,7</h1>
                    <div className="card flex justify-center flex-col items-center">
                        <Rating value={value} onChange={(e) => setValue(e.value)} cancel={false} />
                        <h6 className='text-[#7c8387] font-medium'>Based on 565 ratings</h6>
                    </div>
                </div>

                <div>

                    {renderSectionRate}

                </div>

            </div>
        </div>
    )
}
