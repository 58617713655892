import Button from "../Button/Button";

// data for the product ads
const cardsData = [
    {
        headText: "New Collection",
        productName: "Colored Fashion"
    },
    {
        headText: "-50%",
        productName: "On All Sunglasses"
    }
];

// functional component for the product ads grid
export default function ProductAds() {

    // map over the data and create a new array of JSX elements for each product ad
    const renderCards = cardsData.map((card, index) => {

        return (

            <div key={index} className="advertisment-card relative shadow-md  rounded-[5px] overflow-hidden p-4">

                <div className="w-fit flex items-center flex-col uppercase">

                    {/* headline */}
                    <h3 className="font-bold text-[#d9513b] min-h-[45px]">{card.headText}</h3>

                    {/* product name */}
                    <h1 className="text-[20px] text-white font-bold mb-3 min-w-[201px] text-center">{card.productName}</h1>

                    {/* call-to-action button */}
                    <Button bgColor={"#e94635"} classes={'rounded-[5px] mb-3 text-white'}>Shop Now</Button>

                </div>

            </div>

        );
    });

    // return the grid layout with the product ads
    return (

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-x-8 mt-16">

            {/* render the product ads */}
            {renderCards}

        </div>
    );
}