import React from 'react'

export default function ProductGalleryOverview({ product }) {

    return (

        <div className="mx-auto col-span-12 md:col-span-6 grid grid-cols-12 px-4">

            <div className="col-span-12">

                <img
                
                    src={product.images[0].src}

                    alt={product.images[0].alt}

                    className="h-full w-full object-cover object-center rounded-[10px]" />

            </div>

            <div className="col-span-12 grid grid-cols-12 gap-3 my-5">

                <div className="col-span-6">

                    <img
                        src={product.images[1].src}
                        alt={product.images[1].alt}
                        className="h-full w-full object-cover object-center rounded-[10px]" />

                </div>

                <div className="col-span-6">

                    <img
                        src={product.images[2].src}
                        alt={product.images[2].alt}
                        className="h-full w-full object-cover object-center rounded-[10px]" />

                </div>

            </div>


        </div>
    )
}
