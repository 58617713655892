import React from 'react'

export default function CallToActionList({ callsToAction }) {
    return (

        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">

            {callsToAction.map((item) => (

                <a key={item.name} href={item.href} className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">

                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

                    {item.name}

                </a>

            ))}

        </div>
    )
}
