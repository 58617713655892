import { faShoppingCart, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToCart } from "../../../store/reducers/cartReducer";
import { useDispatch } from "react-redux";

// This component displays icons for adding a product to a user's wishlist and cart
export default function ProductIcon({ product }) {

    const dispatch = useDispatch();

    return (
        <div className="product-links text-right relative z-50 select-none">

            {/* Icon for adding product to wishlist */}
            <span className="inline-block cursor-pointer ml-5 text-gray-400  transition duration-300 ease-in-out text-lg">

                <FontAwesomeIcon icon={faHeart} onClick={(e) => {

                    e.target.classList.toggle('text-red-500');

                    dispatch(addToCart(product));

                }}  >
                </FontAwesomeIcon>

            </span>

            {/* Icon for adding product to cart */}
            <span className="inline-block cursor-pointer ml-5 text-gray-400  transition duration-300 ease-in-out text-lg">

                <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>

            </span>

        </div>
    )
}