
import { Link } from "react-router-dom";

import React from 'react'
import HowItWorksLink from './HowItWorksLink/HowItWorksLink';
import PolicyLinks from './PolicyLinks/PolicyLinks';
import MarketplaceLink from './MarketplaceLink/MarketplaceLink';
import SupportLinks from './SupportLinks/SupportLinks';
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks';
import TermsLinks from "./TermsLinks/TermsLinks";

export default function Footer() {

    return (

        <footer className="bg-gray-100">

            <div className="mx-auto max-w-2xl px-4 sm:px-6 py-8 lg:max-w-7xl lg:px-8">

                <div className="grid span grid-cols-12 gap-6 p-3 mb-3 border-b-2 border-gray-200">

                    <HowItWorksLink />

                    <PolicyLinks />

                    <MarketplaceLink />

                    <SupportLinks />

                </div>

                <div className="grid span grid-cols-12 gap-6 p-3 mb-3">

                    <SocialMediaLinks />

                    <Link className="col-span-12 sm:col-span-6 flex justify-center sm:justify-end items-center">

                        <img src={require('./payments.webp')} alt='our brand' className="py-2.5 max-h-20 w-auto" height="37" width="150" />

                    </Link>

                </div>

                <div className='py-5 text-center'>

                    <h3 className='font-bold text-gray-500'>Copyright © {new Date().getFullYear()} ourBrand.com platform. All rights reserved</h3>

                    <TermsLinks />

                </div>

            </div>

        </footer>
    )
}
