
const setInvalidInputStyle = (target, inputName, setInputErrorMessage, hintMessage) => {

    setInputErrorMessage(prevState => ({

        ...prevState,

        [inputName]: `${hintMessage || ""}`

    }));

    target.style.borderColor = "red";

}

const setValidInputStyle = (target, inputName, setInputErrorMessage) => {

    setInputErrorMessage(prevState => ({

        ...prevState,

        [inputName]: ``

    }));

    target.style.borderColor = "#e4ebe4";

}

export const validateInput = (target, inputName, setInputErrorMessage, password, setPassword) => {

    const nameRegex = /^[\u0621-\u064Aa-zA-Z]*$/;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const passwordRegex = /^(?=.*[a-zA-Z].*[a-zA-Z]).{8,16}$/;

    const phone = /^\d+$/;

    const checkForValidRegex = (regex, errorMessage) => {

        // Use the .test() method with regular expressions instead of the .match() method as it provides better performance.

        if (regex.test(target.value)) {

            setValidInputStyle(target, inputName, setInputErrorMessage);

        } else {

            setInvalidInputStyle(target, inputName, setInputErrorMessage, errorMessage);

        }

    }

    switch (inputName) {

        case "First Name":
        case "Last Name":

            checkForValidRegex(nameRegex, "Please use only letters and no spaces in your name.");

            break;

        case "Email":

            checkForValidRegex(emailRegex, "Please enter a valid email address.");

            break;

        case "Phone":

            checkForValidRegex(phone, "Please enter a valid Phone Number.");

            break;

        case "Password":

            setPassword(target.value);

            checkForValidRegex(passwordRegex, "Please enter a valid password that starts with 8 characters, including at least 2 letters.");

            break;

        case "Confirm Password":

            // Added if statements instead of switch statements for better readability.
            if (target.value === password) {

                setValidInputStyle(target, inputName, setInputErrorMessage);

            } else {

                setInvalidInputStyle(target, inputName, setInputErrorMessage, "Please enter the same password that you wrote for the previous input to confirm your password.");

            }

            break;

    }
}

export const products = [
    {
        id: 1,
        name: 'Earthen Bottle',
        href: '#',
        price: '$48',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg',
        imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
        id: 2,
        name: 'Nomad Tumbler',
        href: '#',
        price: '$35',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg',
        imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
        id: 3,
        name: 'Focus Paper Refill',
        href: '#',
        price: '$89',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg',
        imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
        id: 4,
        name: 'Machined Mechanical Pencil',
        href: '#',
        price: '$35',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg',
        imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    // More products...
    {
        id: 5,
        name: 'Earthen Bottle',
        href: '#',
        price: '$48',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg',
        imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
        id: 6,
        name: 'Nomad Tumbler',
        href: '#',
        price: '$35',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg',
        imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
        id: 7,
        name: 'Focus Paper Refill',
        href: '#',
        price: '$89',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg',
        imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
        id: 8,
        name: 'Machined Mechanical Pencil',
        href: '#',
        price: '$35',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg',
        imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    // More products...
]