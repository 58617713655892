import React, { useState, useEffect } from 'react';
import { DataScroller } from 'primereact/datascroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import ProductCount from '../../ProductCount/ProductCount';

// This class provides a static method for getting product data
class ProductService {
    static async getProducts() {
        const data = {
            "products": [
                {
                    "id": 1,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/green-earbuds.jpg",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    "price": 10.99
                },
                {
                    "id": 2,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/bamboo-watch.jpg",
                    "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    "price": 19.99
                },
                {
                    "id": 3,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/blue-t-shirt.jpg",
                    "description": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    "price": 5.99
                },
                {
                    "id": 1,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/green-earbuds.jpg",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    "price": 10.99
                },
                {
                    "id": 2,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/bamboo-watch.jpg",
                    "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    "price": 19.99
                },
                {
                    "id": 3,
                    "name": "Focus Paper Refill Focus Paper Refill Focus Paper Refill",
                    img: "https://primefaces.org/cdn/primereact/images/product/blue-t-shirt.jpg",
                    "description": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    "price": 5.99
                }

            ]
        }
        return data.products;
    }
}

// This component displays a list of products with the ability to add or remove products and updates the total price accordingly
export default function PaymentPrdoucts({ totalPrice, setTotalPrice }) {

    const [products, setProducts] = useState([]);

    useEffect(() => {

        // Retrieve the product data and update the total price
        ProductService.getProducts().then((data) => {

            setProducts(data);

            data.map(product => {

                setTotalPrice(prev => prev + product.price);

            })

        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // This function returns the template for each product in the list
    const itemTemplate = (data) => {

        return (

            <div className="w-full flex items-center justify-between !mb-3 py-3">

                {/* Display the product image */}
                <img className="w-[100px] h-full shadow-2 block xl:block" src={data.img} alt={data.name} />

                <div className='w-full ms-5'>

                    <div className='flex justify-between'>

                        <div>

                            {/* Display the product name and count component */}
                            <h1 className='font-medium mb-3'>{data.name.slice(0, 30)}</h1>

                            <div className='w-fit'>
                                <ProductCount price={+data.price} totalPrice={totalPrice} setTotalPrice={setTotalPrice} />
                            </div>

                        </div>

                        {/* Display the remove product icon */}
                        <FontAwesomeIcon icon={faClose} className=' cursor-pointer'></FontAwesomeIcon>

                    </div>

                </div>

            </div>
        );
    };

    return (
        <div className="my-10 w-full lg:w-[500px]">

            {/* Display the list of products */}
            <DataScroller value={products} itemTemplate={itemTemplate} rows={5} inline scrollHeight="250px" header="Scroll Down to All Of Products" />

        </div>
    )
}