import React from 'react'
import { Link } from 'react-router-dom'

export default function TermsLinks() {
    return (

        <ul className='flex justify-center items-center mt-3'>

            <Link to={"/"} className='me-2 font-bold'>

                <li>Contact Us</li>

            </Link>

            <Link to={"/"} className='me-2 font-bold'>

                <li>Privacy policy</li>

            </Link>

            <Link to={"/"} className='me-2 font-bold'>

                <li>Terms of use</li>

            </Link>

            <Link to={"/"} className='me-2 font-bold'>

                <li>Blog</li>

            </Link>

        </ul>

    )
}
