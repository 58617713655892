import { faHeart, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProductActions() {
    return (

        <div className="flex items-center flex-col md:flex-row">

            <div className="text-gray-500 me-0 md:me-3 mb-3 md:mb-0 border-2 px-3 rounded-[6px] cursor-pointer w-full md:w-auto" onClick={e => e.currentTarget.children[0].classList.toggle('text-red-600')}>

                <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>

                <span className="mx-2 font-bold">Save</span>

            </div>

            <div className="text-gray-500 border-2 px-3 rounded-[6px] cursor-pointer">

                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>

                <span className="mx-2 font-bold">Delete</span>

            </div>

        </div>

    )
}
