import { StarIcon } from '@heroicons/react/20/solid'
import ReviewsAndRatings from '../../../ReviewsAndRatings/ReviewsAndRatings'
import { useState } from 'react'
const reviews = { href: '#', average: 4, totalCount: 117 }
export default function ProductReviews({ classNames, setSeeReviews }) {

    return (
        <div className="mt-6">

            <h3 className="sr-only">See all 512 reviews</h3>

            <div className="flex items-center justify-between">

                <div className="flex items-center">

                    {[0, 1, 2, 3, 4].map((rating) => (

                        <StarIcon

                            key={rating}

                            className={classNames(

                                reviews.average > rating ? 'text-yellow-400' : 'text-gray-200',

                                'h-5 w-5 flex-shrink-0'

                            )}

                            aria-hidden="true"

                        />

                    ))}

                </div>

                <a href={reviews.href} onClick={() => setSeeReviews(true)} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">

                    See all {reviews.totalCount}   reviews

                </a>

            </div>

        </div>
    )
}
