import cartReducer from "./reducers/cartReducer";

const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
    reducer: {
        cartSlice: cartReducer
    }
});

export default store;