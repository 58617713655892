
export default function ProductFabric({ product }) {
    return (

        <div className="mt-10 pt-5 border-t border-gray-500">

            <h3 className="text-sm font-medium text-gray-900">Fabric & Care</h3>


            <div className="mt-4">

                <ul role="list" className="space-y-2 pl-4 text-sm">

                    {product.highlights.map((highlight) => (

                        <li key={highlight} className="text-gray-400 list-outside list-disc">

                            <span className="text-gray-600">{highlight}</span>

                        </li>

                    ))}

                </ul>

            </div>

        </div>
    )

}
