import React from 'react'

export default function ProductVariants({ options , selectType }) {

    return (

        <select className="border-2 px-2 rounded-[10px] text-[11px] sm:text-[16px] w-full md:w-auto min-w-[80px] cursor-pointer me-3">
            
            <option value={1} hidden>{selectType}</option>
            
            {
                options.map((opetion, index) => {
                    return (
                        <option key={index} value={index + 2} >{opetion}</option>
                    )
                })
            }

        </select>
    )
}
