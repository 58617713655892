import "./style.css";
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Rating } from "primereact/rating";


export default function Company() {

    const events = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.status} subTitle={item.date}>
                {item.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`} alt={item.name} width={200} className="shadow-1" />}
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                <Button label="Read more" className="p-button-text"></Button>
            </Card>
        );
    };


    return (
        <div>
            <div style={{ minHeight: 'calc(100vh - 80px)' }}>


            </div>
            <div className="relative min-h-[450px]">

                <img src={require('../../images/laptop.png')} className="w-[700px] absolute left-[50%] translate-x-[-50%] top-[-400px] z-[200]" />

            </div>
            <div className="section-bg">

                <img src={require('../../images/logo.png')} className="z-20 absolute left-[50%] top-[40%] translate-x-[-50%] translate-y-[-40%]" />

            </div>

            <div className="container py-10">

                <div className="card">
                    <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                </div>

                <div className="my-12">

                    <h1 className="font-medium text-[30px] text-center mb-3">What Our Customers Says</h1>

                    <p className="font-medium text-center text-[#637381] mb-10">There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.</p>

                    <div className="grid grid-cols-12 gap-8 my-16">

                        <div className="col-span-4 shadow p-4">
                            <Rating value={5} disabled cancel={false} />

                            <p className="text-[#637381] font-medium leading-4 my-3 text-[16px]">“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.</p>

                            <div className="flex items-center">
                                <div class="me-5">

                                    <img alt="author" src={require('../../images/user.webp')} />

                                </div>

                                <div>

                                    <h2 className="mb-0 font-medium">Mahmoud Nabowy</h2>
                                    <p className="text-[13px] font-medium text-[#637381]">nabowy.work@gmail.com</p>

                                </div>
                            </div>



                        </div>
                        <div className="col-span-4 shadow p-4">
                            <Rating value={5} disabled cancel={false} />

                            <p className="text-[#637381] font-medium leading-4 my-3 text-[16px]">“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.</p>

                            <div className="flex items-center">
                                <div class="me-5">

                                    <img alt="author" src={require('../../images/user.webp')} />

                                </div>

                                <div>

                                    <h2 className="mb-0 font-medium">Mahmoud Nabowy</h2>
                                    <p className="text-[13px] font-medium text-[#637381]">nabowy.work@gmail.com</p>

                                </div>
                            </div>



                        </div>
                        <div className="col-span-4 shadow p-4">
                            <Rating value={5} disabled cancel={false} />

                            <p className="text-[#637381] font-medium leading-4 my-3 text-[16px]">“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.</p>

                            <div className="flex items-center">
                                <div class="me-5">

                                    <img alt="author" src={require('../../images/user.webp')} />

                                </div>

                                <div>

                                    <h2 className="mb-0 font-medium">Mahmoud Nabowy</h2>
                                    <p className="text-[13px] font-medium text-[#637381]">nabowy.work@gmail.com</p>

                                </div>
                            </div>



                        </div>



                    </div>

                </div>

            </div>

        </div>
    )
}
