
// import required modules
import { Swiper } from 'swiper/react';
import { Autoplay } from 'swiper';
export default function SwiperWrapper({ items, slidesPerViewCount, autoplayDelay }) {
    return (
        <Swiper
            spaceBetween={20}

            autoplay={{

                delay: autoplayDelay,

                disableOnInteraction: false,

            }}

            loop={true}

            pagination={{

                clickable: true,

            }}

            modules={[Autoplay]}

            breakpoints={{

                // when window width is >= 640px
                550: {
                    slidesPerView: slidesPerViewCount[0],
                    spaceBetween: 20
                },

                // when window width is >= 768px
                768: {
                    slidesPerView: slidesPerViewCount[1],
                    spaceBetween: 30
                },

                // when window width is >= 1024px
                1024: {
                    slidesPerView: slidesPerViewCount[2],
                    spaceBetween: 40
                },

                // when window width is >= 1024px
                1366: {
                    slidesPerView: slidesPerViewCount[3],
                    spaceBetween: 40
                }

            }}

            className="mySwiper select-none">

            {/* That's Will Render Items To UI */}
            {items}

        </Swiper>
    )
}
