import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import ProductOverviews from './Components/ProductOverviews/ProductOverviews';
import CartProduct from './Components/Cart/CartProduct';
import Login from './Components/Login/Login';
import Regeister from './Components/SignUp/Regeister';
import BackToTopButton from './Components/BackToTopButton/BackToTopButton';
import Categories from './Components/Categories/Categories';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import Payment from './Components/Cart/Payment/Payment';
import Policy from './Components/Policy/Policy';
import Footer from './Components/Footer/Footer';
import { useEffect } from 'react';
import SearchPage from './Components/SearchPage/SearchPage';
import Company from './Components/Company/Company';


function App() {

  const location = useLocation().pathname;

  useEffect(() => {

    const header = document.querySelector('header');
    const headerButtons = document.querySelectorAll('header button');
    const headerLinks = document.querySelectorAll('header a');
    const headerIcons = document.querySelectorAll('header svg');

    const setStyleToCollactionOfItems = (items, style) => {
      return items.forEach(item => {
        item.style.cssText += `color: ${style} !important`;
      });
    }

    if (location === "/company") {

      setStyleToCollactionOfItems(headerButtons, "white");
      setStyleToCollactionOfItems(headerLinks, "white");
      setStyleToCollactionOfItems(headerIcons, 'white');

      header.style.cssText += "background-color: transparent !important;";
    } else {

      setStyleToCollactionOfItems(headerButtons, "black");
      setStyleToCollactionOfItems(headerLinks, "black");
      setStyleToCollactionOfItems(headerIcons, 'black');
      header.style.cssText += "background-color: white !important;";
    }


  }, [location]);

  useEffect(() => {

    window.scrollTo({


      left: 0,

      top: 0,

      behavior: "smooth"

    });
  }, [location]);

  return (

    <div className="App">

      <Header />

      <BackToTopButton />

      <Routes>

        <Route path='/' element={<Home />} />

        <Route path='/policy' element={<Policy />} />

        <Route path='/productOverviews' element={<ProductOverviews />} />

        <Route path='/cart' element={<CartProduct />} />

        <Route path='/cart/payment' element={<Payment />} />

        <Route path='/regeister' element={<Regeister />} />

        <Route path='/login' element={<Login />} />

        <Route path='/categories' element={<Categories />} />

        <Route path='/search' element={<SearchPage />} />

        <Route path='/company' element={<Company />} />

      </Routes>

      <Footer />

    </div>

  );
}

export default App;
