import ProductIcon from "./ProductIcon/ProductIcon";

// This component displays the details of a product, including the price and icons for adding the product to a user's wishlist and cart
export default function ProductDetails({ product }) {

    return (

        <div className="product-bottom-details overflow-hidden border-t border-gray-300 pt-5">

            {/* Display the price of the product */}
            <div className="product-price float-left text-lg font-semibold text-yellow-600">

                <small className="text-sm font-normal text-gray-500 line-through mr-1">{product.price}</small>{product.price}

            </div>

            {/* Display icons for adding product to user's wishlist and cart */}
            <ProductIcon product={product} />

        </div>
    )
}